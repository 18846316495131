<template>
    <layout-cabinet>
        <template slot="content">
            <router-view />
        </template>
    </layout-cabinet>
</template>

<script>
import LayoutCabinet from '@/views/layouts/LayoutCabinet';

export default {
    name: 'Index',
    components: {
        LayoutCabinet
    },
    created() {
        this.$accessManager.autoLoginUser();
    }
};
</script>

<style scoped>

</style>
